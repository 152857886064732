.user-information-profile-image {
    width: 110px;
    height: 110px;
    flex-shrink: 0;
    border-radius: 50%;
    background: white;
  }
  
  .user-icon-change-profile-button {
    border-radius: 40px;
    background: #AD5CDA;
    display: inline-flex;
    padding: 10px 32px;
    align-items: center;
    gap: 5px;
    border: none;
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    position: absolute;
    left: 6px;
    top: 140px;
  }