.client-wrapper {
    background-color: #ffff;
    border-radius: 20px;
    padding: 35px 20px;
}

.page-wrapper {
    margin-left: 270px;
    padding-top: 60px;
    position: relative;
    transition: all 0.4s ease;
    max-width: 1680px;
}

.page-wrapper>.content {
    padding: 1.875rem 1.875rem 0 1.875rem;
}

.page-header {
    display: flex;
    justify-content: space-between;
}

.sub-title {
    font-size: 20px;
    font-weight: 400;
    color: #000000;
    line-height: 23.7px;
}

.page-title {
    font-size: 32px;
    font-weight: 500;
    color: #000000;
    line-height: 47.4px;
}

.sub-title::before {
    float: left;
    color: #000000;
    content: "/";
}

.table .thead-light th {
    color: #000000 !important;
    border-color: #c6abd6 !important;
    background-color: #f3ebf8 !important;
    font-size: 20px;
    font-weight: 500;
}

.active-btn {
    height: 40px;
    width: 106px;
    font-weight: 400;
    opacity: none !important;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: #29a02e;
    border: 1px solid transparent;
    font-size: 18px;
    line-height: 21.33px;
    border-radius: 20px;
    transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}

.inactive-btn {
    height: 40px;
    width: 106px;
    font-weight: 400;
    opacity: none !important;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: #DC3545;
    border: 1px solid transparent;
    font-size: 18px;
    line-height: 21.33px;
    border-radius: 20px;
    transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}

.client-user-cards-table {
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 30px;
    background-color: #fff;
    border: 1px solid #ad5adc;
}

.client-user-card-table .table td,
.client-user-card-table .table th {
    border-top: 1px solid #e2e5e8;
    white-space: nowrap;
    text-align: left;
}

.client-user-card-table .card-body {
    padding: 0px;
}


.client-user-card-table .table td,
.client-user-card-table .table th {
    border-top: 1px solid #e2e5e8;
    padding: 0 !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    padding-left: 10px !important;
    white-space: nowrap;
}

.client-user-card-table .table td:nth-child(4),
.client-user-card-table .table th:nth-child(4) {
    width: 12%;
    padding-right: 10px !important;
}

.client-user-card-table .table td:nth-child(3),
.client-user-card-table .table th:nth-child(3) {
    width: 23%;
}

.client-user-card-table .table td:nth-child(2),
.client-user-card-table .table th:nth-child(2) {
    width: 35%;
}

.client-user-card-table .table td:nth-child(1),
.client-user-card-table .table th:nth-child(1) {
    width: 30%;
    padding-left: 20px !important;
}