.modal-header-box {
  background-color: #AD5ADC !important;
  border-radius: 10px !important;
 
}

.modal-header-box .modal-title {
  color: #fff;
}

.detail-modal-close-btn {
  border: none;
  
  background-color: #7251aa !important;
  color: white;
}

.detail-modal-container .modal-content {
  border-radius: 10px !important;
  border: 2px solid #AD5ADC !important;
  padding: 20px 20px 0px 20px
}

.close-btn{
  border: none;
 
  background-color: #AD5ADC !important;
  color: white;
}