.header-small-logo {
  width: 130px;
  height: auto;
}

.header-logo {
  width: 130px;
  height: auto;
}

.header-toggle-btn {
  color: #AD5ADC !important;
}

.sidebar-nav-li {
  background: red !important;
}

.logout-Button {
  width: 100%;
  padding-left: 1.2rem;
  color: white;
  background: transparent;
  border: navajowhite;
  margin-top: 10px;
}

.sidemenu-active{
  border-radius: 10px;
}

@media only screen and (min-width: 320px) {
    .mini-sidebar .sidebar {
        width: auto !important;
    }
}