.insight-stickey-actions-heading {
  position: sticky;
  left: 0px;
  z-index: 0;
  /* color: #fff; */
  background-color: #111724;
}

.insight-stickey-actions-body {
  position: sticky;
  left: 0px;
  z-index: 1111 !important;
  /* background-color: #0b0f18 !important; */
  padding-left: 0px !important;
}

.insight-stickey-actions-btn {
  padding: 4px 4px !important;
}

.description-box-ui {
  border: 0.5px solid #bec3c7;
  border-radius: 4px;
  padding: 10px 5px;
}

.insight-stickey-actions-body {
  text-align: center !important;
  padding-left: 10px !important;
}

.table-text-alignment{
  text-align: right !important;
}

.table.table-center td {
  vertical-align: middle;
  text-align: center;
}