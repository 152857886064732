.reset-title {
  color: #fff !important;
}

.reset-subtitle {
  color: #fff;
  font-size: 17px;
  margin-bottom: 1.875rem;
  text-align: center;
}

.reset-header-logo {
  width: 30%;
  margin: 0 auto;
}

.reset-submit-btn {
  color: #fff !important;
  gap: 15px;
  background-color: #AD5CDA;
  border: none;
}

.reset-submit-btn:hover {
  background-color: #AD5CDA !important;
  border: none !important;
}

.reset-submit-btn:active {
  background-color: #AD5CDA !important;
  border: none !important;
}

.reset-submit-btn:focus {
  background-color: #AD5CDA !important;
  border: none !important;
}

.remember-text-class-link {
  /* color: #fff !important; */
  margin-right: 5px;
}
