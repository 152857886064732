.toggle-password-custom {
  position: absolute;
  right: 15px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  color: black;
  cursor: pointer !important;
}

.custom-input {
  border: 1px solid #dee2e6 !important;
  box-shadow: none !important;
  /* color: #fff !important; */
  padding: 7px !important;
  font-size: 0.875rem !important;
  line-height: 1.6 !important;
  font-weight: 400 !important;
  color: black !important;
  width: 100% !important;
  border-radius: 8px !important;
}