.no-profile-manage {
  width: 80px;
}

.no-data {
  /* background: white; */
  height: 100%;
  text-align: center;
  margin-top: 1rem;
  padding: 1rem;
  /* color: #fff; */
}

.admin-profile{
  width: 95%;
  height: 260px;
  border-radius: 10px;
}

.admin-content{
    border: 1px solid #AD5ADC;
    padding: 5px 10px;
    border-radius: 10px;
    font-weight: 500;
    margin-top: 5px;
}

.active-badge{
    border: 1px solid #29A02E;
    padding: 0px 10px;
    border-radius: 10px;
    font-weight: 400;
}

.inactive-badge{
    border: 1px solid red;
    padding: 0px 10px;
    border-radius: 10px;
    font-weight: 400;
}