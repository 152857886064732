
.forgot-subtitle {
  font-size: 17px;
  margin-bottom: 1.875rem;
  text-align: center;
}

.forgot-header-logo {
  /* width: 30%; */
  width: 20% !important;
  margin: 0 auto;
}

.forgot-submit-btn {
  color: #fff !important;
  gap: 15px;
  background-color: #AD5CDA;
  border: none;
}

.forgot-submit-btn:hover {
  background-color: #AD5CDA !important;
  border: none !important;
}

.forgot-submit-btn:active {
  background-color: #AD5CDA !important;
  border: none !important;
}

.forgot-submit-btn:focus {
  background-color: #AD5CDA !important;
  border: none !important;
}

.remember-text-class-link {
  /* color: #fff !important; */
  margin-right: 5px;
}
