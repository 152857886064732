/* Custom icon size */
.custom-icon .swal2-icon-content {
  font-size: 30px !important;
  color: black !important;
}


.custom-title {
  color: black;
  font-weight: 500;
}


.custom-cancel-button {
  background-color: #FFFFFF !important;
  color: black !important;
  padding: 10px 20px !important;
  border: 1px solid black !important;
  border-radius: 10px !important;
}

.custom-cancel-button:hover {
  background-color: #FFFFFF !important;
}

/* Custom delete button */
.custom-delete-button {
    background-color: #AD5ADC !important;
    /* color: white !important; */
    border: 1px solid #AD5ADC !important;
}

div:where(.swal2-container) div:where(.swal2-popup){
    /* color: white !important; */
    border-radius: 20px !important;
    border: 2px solid #AD5ADC !important;
    
}

div:where(.swal2-container) div:where(.swal2-popup) button:where(.swal2-styled).swal2-confirm.swal2-confirm{
  color: white !important;
  padding: 10px 20px !important;
  border: 1px solid #AD5ADC !important;
  border-radius: 10px !important;
  background-color: #AD5ADC !important;
}

div:where(.swal2-icon).swal2-warning {
    border-color: black;
    color: black;
}

div:where(.swal2-icon){
    width: 2em !important;
    height: 2em !important;
}

div:where(.swal2-container) div:where(.swal2-actions):not(.swal2-loading) .swal2-styled:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)) !important;
}

