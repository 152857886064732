.admin-wrapper {
  background-color: #ffff;
  border-radius: 20px;
  padding: 35px 20px;
}

.page-header {
  display: flex;
  justify-content: space-between;
}

.text-color {
  color: #ad5adc !important;
  font-size: 20px;
  font-weight: 400;
}

.sub-title {
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  line-height: 23.7px;
}

.page-title {
  font-size: 32px;
  font-weight: 500;
  color: #000000;
  line-height: 47.4px;
}

.sub-title::before {
  float: left;
  color: #000000;
  content: "/";
}

.searchbar-custom {
  border: 2px solid #00000061;
  padding: 13px 20px !important;
  font-size: 20px;
  line-height: 23.7px;
  font-weight: 400;
  width: 100%;
  height: auto;
  max-height: 46px;
  border-radius: 20px;
}

.searchbar-container {
  position: relative;
  padding: 20px 0px;
}

.searchbar-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.searchbar-icon img {
  width: 20px;
  height: 20px;
}

.button {
  padding: 12px 25px;
  display: inline-block;
  font-weight: 400;
  height: 48px;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 20px;
  line-height: 23.7px;
  border-radius: 20px;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.button-them-gray {
  background-color: #ad5adc !important;
  color: white !important;
  border: 2px solid white;
}

.cards-table {
  /* box-shadow: 0px 4px 8px rgb(0 0 0 / 16%); */
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 30px;
  background-color: #fff;
  border: 1px solid #ad5adc;
  /* opacity: 50%; */
}
.card-table .card-body {
  padding: 0px;
}

/* .table .thead-light th {
      color: #495057;
      background-color: #e9ecef;
      border-color: #dee2e6;
    } */

.table .thead-light th {
  color: #000000 !important;
  border-color: #c6abd6 !important;
  background-color: #f3ebf8 !important;
  font-size: 20px;
  font-weight: 500;
  /* text-align: left; */
  /* line-height: 28.44px; */
}

table thead th:first-child {
  border-radius: 20px 0 0 0 !important;
}

table thead th:last-child {
  border-radius: 0 20px 0 0 !important;
}



.card-table .gui-table .table  td:nth-child(2),
.card-table .gui-table .table  th:nth-child(2) {
  width: 10%;
}

.card-table .gui-table .table  td:nth-child(1),
.card-table .gui-table .table  th:nth-child(1) {
  width: 90%;
}

.page-wrapper {
  margin-left: 270px;
  padding-top: 60px;
  position: relative;
  transition: all 0.4s ease;
  /* width: 100vw; */
  max-width: 1680px;
  /* padding-right: 1.875rem; */
}

.page-wrapper > .content {
  padding: 1.875rem 1.875rem 0 1.875rem;
  /* padding-left: 4.5rem; */
}

.back-button {
  border-radius: 15px;
  padding: 6px 25px;
}

.card .card-body {
  padding: 2% 1%;
}

.card-border {
  border-radius: 20px !important;
}

.section-border {
  border: 1px solid #ad5adc !important;
  border-radius: 15px !important;
  padding: 30px !important;
}

.custom-input {
  border: 1px solid #dee2e6;
  box-shadow: none;
  /* color: #fff !important; */
  padding: 7px !important;
  font-size: 0.875rem;
  line-height: 1.6;
  font-weight: 400;
  color: #1e2022;
  width: 90%;
  border-radius: 8px;
}
.table td {
  font-size: 18px;
  color: #000000 !important;
  font-weight: 400;
  text-align: left;
  /* padding: 15px 25px; */
  /* vertical-align: middle; */
  white-space: nowrap;
}

.card-table .table td,
.card-table .table th {
  border-top: 1px solid #e2e5e8;
  /* padding: 1rem 16px; */
  white-space: nowrap;
  /* text-align: left; */
}

.active-btn {
  /* padding: 13px 35px; */
  height: 40px;
  width: 106px;
  font-weight: 400;
  /* color: #212529; */
  opacity: none !important;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: #29a02e;
  border: 1px solid transparent;
  font-size: 18px;
  line-height: 21.33px;
  border-radius: 20px;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
.pagination-align {
  display: flex;
  justify-content: flex-end;
}
.inactive-btn {
  /* padding: 13px 35px; */
  height: 40px;
  width: 106px;
  font-weight: 400;
  /* color: #212529; */
  opacity: none !important;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: #dc3545;
  border: 1px solid transparent;
  font-size: 18px;
  line-height: 21.33px;
  border-radius: 20px;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
.icon-color {
  fill: #000000;
  width: 30px;
  height: 30px;
}
.add-text {
  padding: 20px 0px;
}

.Table-pagination {
  border-bottom: none !important;
  display: flex;
  justify-content: flex-start;
}

.Table-pagination .MuiTablePagination-toolbar {
  padding-left: 16px; /* Adjust as needed */
}

.Table-pagination .MuiTablePagination-selectRoot {
  margin-right: auto;
  margin-left: 0;
}

.Table-pagination .MuiTablePagination-actions {
  margin-right: auto;
}

.list-image-news {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.name-centered-rounded {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.default-image {
  background-color: #a2f1f7;
  color: #7638ff;
  border: 1px solid #362ead;
  font-size: 20px;
  width: 50px;
  height: 50px;
}

.card-table .table td,
.card-table .table th {
  border-top: 1px solid #e2e5e8;
  padding: 16px 0px !important;
  white-space: nowrap;
}

table td {
  padding: 10px 0px !important;
}

.add-category-button {
  background-color: #ad5adc;
  border-radius: 20px;
  color: #ffffff;
  border: 1px solid #ad5adc;
  width: 184px;
  height: 48px;
  font-size: 22px;
  font-weight: 400;
}

.action-right {
  padding-right: 10px !important;
}




