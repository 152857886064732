label{
    color: black;
}

input{
    background: #F7F5F5;
}

.custom-input {
  border: 1px solid #dee2e6;
  box-shadow: none;
  /* color: #fff !important; */
  padding: 7px !important;
  font-size: 0.875rem;
  line-height: 1.6;
  font-weight: 400;
  color: #1e2022;
  width: 100% !important;
  border-radius: 10px !important;
}