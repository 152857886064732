.login-subtitle {
  font-size: 17px;
  margin-bottom: 1.875rem;
  text-align: center;
}

.login-header-logo {
  width: 10%;
  margin: 0 auto;
}

.login-submit-btn {
  color: #fff !important;
  border: 1px solid #fff;
  gap: 15px;
  /* background-color: #1b243a; */
  background-color: #ad5adc !important;
  border: none;
}


