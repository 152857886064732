.replace-btn{
    background: #AD5ADC;
    color: white;
    padding: 5px 20px;
    border: 1px solid #AD5ADC;
    border-radius: 15px;
}

.remove-btn{
    background: #FFFFFF;
    color: black;
    padding: 5px 20px;
    border: 1px solid #AD5ADC;
    border-radius: 15px;   
}

.img-border {
    border: 1px solid #AD5ADC;
    border-radius: 22px;
    width: 242px;
    height:244px ;
}

.product-profile{
    height: 244px;
    width: 242px;
    background-color: #F7F5F5;
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;  
}

.product-label-profile{
    background-color:#D8D4D4 ;
    border-radius: 22px;
    color:white ;
    display: inline-block;
    padding: 5px 50px;
    margin-top: 15px;
}


@media  (max-width: 1300px) {
    .addproduct-class {
        height: 189px;
        width: 165px;
        background-color: #F7F5F5;
        border-radius: 22px;
        display: flex;
        align-items: center;
        justify-content: center;  
    }
    .label-prod-pro{
        background-color:#D8D4D4 ;
        border-radius: 22px;
        color:white ;
        display: inline-block;
        padding: 5px 22px;
        margin-top: 15px;
    }

    .image-responsive{
        border: 1px solid #AD5ADC;
    border-radius: 22px;
    width: 176px;
    height:194px ;
    }
  }
