.table-text-alignment{
  text-align: right !important;
}

.btn.disabled, .btn:disabled{
  opacity: .45 !important;
}


/* Modal wrapper to center the modal */
.modal-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999; /* Ensure it's above other content */
}

.modal-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000; /* Adjust as necessary */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  /* width: 80%; 
  max-height: 80%; */
  overflow-y: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.size-heading{
  width: 33%;
}
