.modal-header-box {
  /* background-color: #7251aa !important; */
  /* border-bottom: 0.5px solid #9888885c; */
  border: none !important;
}

.modal-header-box .modal-title {
  color: #fff;
}

.detail-modal-close-btn {
  border: none;
  /* border-radius: 50%; */
  background-color: #7251aa !important;
  color: white;
}

.detail-modal-containe .modal-content {
  border-radius: 4px !important;
  border: 0.2px solid #000000 !important;
  /* background-color: #1b253a; */
  /* color: white; */
}

/* .modal-backdrop {
  background: none !important;
} */
