.setting-container {
  display: flex;
}
.setting-button-container {
  width: 20%;
}
.setting-content-container {
  width: 80%;
  background: white;
  padding: 1rem 2rem 1rem 2rem;;
  border-radius: 20px;
}
.setting-button {
  border: none;
  width: 95%;
  padding: 10px 10px;
}
.active-button {
  background-color: #AD5CDA;
  color: white;
  border-radius: 4px;
}

/* Version setting */

.update-version-button {
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: auto;
  width: 50%;
  border: none;
  background: #7251aa;
  padding: 10px 15px;
  border-radius: 4px;
  color: white;

  background: linear-gradient(270deg, #7251aa, #6a4b9e);
  background-size: 400% 400%;

  -webkit-animation: AnimationName 0s ease infinite;
  -moz-animation: AnimationName 0s ease infinite;
  animation: AnimationName 0s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* style.css */

.draggable-item .btn-secondary {
  background-color: blue;
  color: white;
  margin-right: 10px;
}

.message-content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.custom-button {
  display: inline-block;
  padding: 5px 10px;
  margin: 0 5px;
  background-color: green;
  color: white;
  border: none;
  cursor: pointer;
}

.custom-button:active {
  background-color: darkgreen;
}

/* Ensure draggable elements in the editor */
.custom-button[draggable="true"] {
  user-select: none;
}


table.table--light thead th {
  border: none;
  color: #ffffff;
  background-color: #AD5CDA;
}

table thead th:first-child {
  border-radius: 5px 0 0 0;
}

table thead th:last-child {
  border-radius: 0 5px  0 0;
}
table th:first-child {
  text-align: left;
  font-weight: 600;
}

table th:last-child {
  text-align: right;
}

.table th {
  font-size: 20px;
  text-align: center;
  padding: 15px 25px;
  white-space: nowrap;
}

.table td {
  font-size: 0.8125rem;
  color: #5b6e88;
  font-weight: 500;
  padding: 15px 0px;
  vertical-align: middle;
  white-space: nowrap;
}

.table td span {
  font-size:  0.8125rem;
}

.setting-border {
  border: 1px solid #ad5adc !important;
  border-radius: 22px !important;
  padding: 0px !important;
}


/* table td:last-child {
  text-align: right;
} */

.table tbody tr:last-child td {
  border-bottom: none;
}

.short-codes:before {
  content: 'Copy';
  position: absolute;
  height: 0%;
  width: 100%;
  background: #4634ff;
  overflow: hidden;
  color: #fff;
  cursor: pointer;
  text-align: center;
  border-radius: 3px;
}

table thead th:last-child {
  border-radius: 0 0 5px  0;
}

.copyable-text {
  cursor: pointer;
  display: inline-block;
  position: relative;
}

.copyable-text .text {
  visibility: visible;
}

.copyable-text .copy-label, .copyable-text .copied-label {
  display: none;
  background-color: blue;
  color: white;
  padding:  5px 20px 5px 20px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  white-space: nowrap;
}

.copyable-text:hover .text {
  visibility: hidden;
}

.copyable-text:hover .copy-label {
  display: inline;
}

.copyable-text.copied .text {
  visibility: visible;
}

.copyable-text.copied .copy-label {
  display: none;
}

.copyable-text.copied .copied-label {
  display: inline;
}

.setting-button-mobile {
  border: none;
  width: 100%;
  padding: 10px 10px;
}

.setting-content-container-mobile {
  width: 100%;
  background: white;
  padding: 3rem 2rem 1rem 2rem;;
  border-radius: 4px;
}

@media only screen and (max-width: 430px) {
  .chat-btn {
    width: 100%;
    /* background-color: lightblue; */
  }
}
