.table-text-alignment{
  text-align: right !important;
}

.btn.disabled, .btn:disabled{
  opacity: .45 !important;
}

.img{
  width: 10%;
  height: auto;
}

@media screen and (max-width: 768px) {
.img-size{
    width: 38%;
    height: auto;
  }
}

td.user-img-padding-remove {
  position: relative;
}
td.user-img-padding-remove:before {
  content: url(data:image/svg+xml;base64,PHN2ZyBzdHJva2U9IiNhYWEiIGZpbGw9IiM0OTUwNTciIHN0cm9rZS13aWR0aD0iMCIgdmlld0JveD0iMCAwIDI0IDI0IiBoZWlnaHQ9IjFlbSIgd2lkdGg9IjFlbSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTggMTFWOEwyMiAxMkwxOCAxNlYxM0gxM1YxOEgxNkwxMiAyMkw4IDE4SDExVjEzSDZWMTZMMiAxMkw2IDhWMTFIMTFWNkg4TDEyIDJMMTYgNkgxM1YxMUgxOFoiPjwvcGF0aD48L3N2Zz4=);
  position: absolute;
    top: 20px;
    left: 15px; 
}

.user-padding-remove img{
  max-width: 43px !important;
}
/* table tbody tr{
  width: 100% !important;
} */
