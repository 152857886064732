.card-body-p {
  font-size: 18px;
}

.table.table-center td {
  vertical-align: middle;
  text-align: center;
}

.custom-card-header{
  background-color: #F3EBF8 !important;
}

.custom-popularcard{
  box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
  border-radius: 25px;
  overflow: hidden;
  margin-bottom: 30px;
  background-color: #fff;
  border: 1px solid #AD5ADC;
}

.border-card{
  border: 1px solid #AD5ADC;
}