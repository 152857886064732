.table-text-alignment{
  text-align: right !important;
}

.btn.disabled, .btn:disabled{
  opacity: .45 !important;
}

.text{
  text-align: center !important;
}



/* .user-padding-remove img{
  max-width: 43px !important;
} */